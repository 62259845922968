<template>
  <div :class="{'calendar-fullscreen': $store.state.calendar.fullscreen}" class="tw-flex tw-flex-col tw-flex-grow">
    <div class="app-calendar tw-border tw-rounded-lg">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          :class="{show: isCalendarOverlaySidebarActive}"
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        >
          <calendar-sidebar @is-event-handler-sidebar-active="createEvent" :params="paramsSettings" @on-events="onEvents" />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card border-calendar mb-0 rounded-0 tw-border-none">
            <div class="card-body pb-0">
              <full-calendar
                id="calendar"
                :key="calendarKey"
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
                @eventRender="updateCalendar"
              />
            </div>
          </div>
        </div>

        <!--             Sidebar Overlay -->
        <div
          :class="{show: isCalendarOverlaySidebarActive}"
          class="body-content-overlay"
          @click="isCalendarOverlaySidebarActive = false"
        />

        <calendar-meeting
          ref="calendarMeeting"
          v-model="isEventHandlerSidebarActive"
          :clear-event-data="clearEventData"
          :event="event"
          @cancellesson="openModal"
          @close="closeEvent"
          @transfer="openModal"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar, {Calendar} from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import {console_logger} from '@/assets/scripts/scripts';
import {BButton, BCardText, BFormGroup} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import CalendarSidebar from '@/components/Calendar/calendar-sidebar/CalendarSidebar';
import TasksService from '@/services/tasks.service';
import CalendarMeeting from '@/components/Calendar/calendar-event-handler/CalendarMeeting';
import {eventBus} from '@/main';
import DashboardService from '@/services/dashboard.service';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarMeeting,
    BCardText,
    BButton,
    flatPickr,
    vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      paramsSettings: [
        {
          label: 'Hide weekends',
          color: 'sky',
          eventName: 'toggle-weekends',
        },
        {
          label: 'Show rows',
          color: 'blue',
          eventName: 'toggle-weeken',
        },
      ],
      calendarKey: 0,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        headerToolbar: {
          left: 'prev,today,next,title',
          right: 'timeGridOneDay,dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        views: {
          timeGridOneDay: {
            type: 'listDay',
            duration: {days: 1},
            buttonText: 'Current Day',
          },
        },
        height: '100%',
        contentHeight: '100%',
        initialView: 'timeGridOneDay',
        events: [],
        eventContent: arg => {
          let divEl = document.createElement('div');
          let start = moment.utc(arg.event._instance.range.start).format('HH:mm');

          if (arg.event._def.extendedProps.calendar === 'Task') {
            divEl.classList.add('event-task');
            divEl.classList.add('bg-light-primary');
            divEl.innerHTML = `
                            <div class="dot-event bg-primary"></div>
                            <div class="mb-0 tw-truncate text-primary font-weight-bold">#${arg.event.extendedProps.taskId} ${arg.event.title}</div>
                        `;
          } else if (arg.event._def.extendedProps.calendar === 'Event') {
            divEl.classList.add('event-meet');
            divEl.innerHTML = `
                            <div class="dot-event"></div>
                            <div class="mb-0 tw-truncate tw-text-sm tw-text-green-600 tw-font-medium">${start} ${arg.event.title}</div>
                        `;
          }

          let arrayOfDomNodes = [divEl];
          return {domNodes: arrayOfDomNodes};
        },
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: 50,
        weekends: true,
        nowIndicator: true,
        eventClassNames: this.eventColor,
        // select: this.handleDateSelect,
        // eventsSet: this.handleEvents,
        locales: allLocales,
        locale: 'en',
        eventResizableFromStart: true,
        droppable: true,
        dragScroll: true,
        navLinks: true,
        // dateClick: function(info) {
        // alert('Clicked on: ' + info.dateStr);
        // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        // alert('Current view: ' + info.view.type);
        // change the day's background color just for fun
        // info.dayEl.style.backgroundColor = 'red';
        // },
        // select: function (info) {
        //     alert('Clicked on: ' + moment(info.start).format('DD.MM.YYYY HH:mm') + ' - ' + moment(info.end).format('DD.MM.YYYY HH:mm'));
        // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        // alert('Current view: ' + info.view.type);
        // change the day's background color just for fun
        // info.dayEl.style.backgroundColor = 'red';
        // },
        /* you can update a remote database when these fire:
                eventAdd:
                eventRemove:
                */
        // eventClassNames({event: calendarEvent}) {
        //     // eslint-disable-next-line no-underscore-dangle
        //     const colorName = this.calendarsColor[calendarEvent._def.extendedProps.calendar]
        //
        //     return [
        //         // Background Color
        //         `bg-light-${colorName}`,
        //     ]
        // },
        eventClick: this.showSidebar,
        // },
        eventChange: this.eventChange,
        select: this.selectArea,
      },
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      handleWindowResize: true,
      blankEvent: {
        title: '',
        start: '',
        end: '',
        allDay: false,
        url: '',
        extendedProps: {
          calendar: '',
          guests: [],
          location: '',
          agenda: '',
        },
      },
      event: {},
      calendarsColor: {
        Business: 'primary',
        Holiday: 'success',
        Personal: 'danger',
        Family: 'warning',
        ETC: 'info',
      },
      calendarApi: null,

      api: null,
      authorized: false,
      items: undefined,
      CLIENT_ID: '664105639150-6mnij0g6gvtlhjcffkc59pionfifv3lo.apps.googleusercontent.com',
      API_KEY: 'AIzaSyCGz14eryTrHWi7LEOZjG6UfblhALE9y-I',
      DISCOVERY_DOCS: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
      SCOPES: 'https://www.googleapis.com/auth/calendar.readonly',
    };
  },
  async created() {
    // this.api = await gapi;
    // this.handleClientLoad();
  },
  mounted() {
    this.updateDataCalendar();
    // this.getEvents()

    // gapi.load('client', this.start)
    // this.api = google.accounts.oauth2.initCodeClient({
    //   client_id: this.CLIENT_ID,
    //   scope: 'https://www.googleapis.com/auth/calendar.readonly',
    //   ux_mode: 'popup',
    //   callback: response => {
    //     console.log(response)
    //     this.authorized = true
    //     this.listUpcomingEvents()
    //     // const xhr = new XMLHttpRequest()
    //     // xhr.open('POST', code_receiver_uri, true)
    //     // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    //     // // Set custom header for CRSF
    //     // xhr.setRequestHeader('X-Requested-With', 'XmlHttpRequest')
    //     // xhr.onload = function () {
    //     //   console.log('Auth code response: ' + xhr.responseText)
    //     // }
    //     // xhr.send('code=' + response.code)
    //   },
    // })
  },
  computed: {
    fullscreen() {
      return this.$store.state.calendar.fullscreen;
    },
    project() {
      return this.$store.state.osBoard.selectedProject;
    },
  },
  watch: {
    fullscreen() {
      this.updateCalendar();
    },
    project() {
      this.updateCalendar();
    },
    '$i18n.locale'() {
      this.calendarOptions.locale = this.$i18n.locale;
      if (this.$i18n.locale === 'ru') {
        this.calendarOptions.views = {
          dayGridMonth: {buttonText: 'Месяц'},
          timeGridWeek: {buttonText: 'Неделя'},
          timeGridDay: {buttonText: 'День'},
          listPlugin: {buttonText: 'Список'},
        };
      } else {
        delete this.calendarOptions.views;
      }
    },
  },
  methods: {
    // start() {
    //   // 2. Initialize the JavaScript client library.
    //   gapi.client
    //     .init({
    //       apiKey: this.API_KEY,
    //       // Your API key will be automatically added to the Discovery Document URLs.
    //       discoveryDocs: ['https://people.googleapis.com/$discovery/rest'],
    //       // clientId and scope are optional if auth is not required.
    //       clientId: this.CLIENT_ID,
    //       scope: 'profile',
    //     })
    //     .then(function () {
    //       // 3. Initialize and make the API request.
    //       return gapi.client.people.people.get({
    //         resourceName: 'people/me',
    //         'requestMask.includeField': 'person.names',
    //       })
    //     })
    //     .then(
    //       function (response) {
    //         console.log(response.result)
    //       },
    //       function (reason) {
    //         console.log('Error: ' + reason.result.error.message)
    //       }
    //     )
    // },
    // async listUpcomingEvents() {
    //   console.log('123123')
    //   let response
    //   try {
    //     const request = {
    //       calendarId: 'primary',
    //       timeMin: new Date().toISOString(),
    //       showDeleted: false,
    //       singleEvents: true,
    //       maxResults: 10,
    //       orderBy: 'startTime',
    //     }
    //     response = await gapi.client.calendar.events
    //       .list({
    //         calendarId: 'primary',
    //         timeMin: moment(this.filters.start).format('YYYY-MM-DDTHH:mm:ss.SZ'),
    //         timeMax: moment(this.filters.end).format('YYYY-MM-DDTHH:mm:ss.SZ'),
    //         showDeleted: false,
    //         singleEvents: true,
    //         maxResults: 10,
    //         orderBy: 'startTime',
    //       })
    //       .then(response => {
    //         console.log(response)
    //         this.items = response.result.items
    //       })
    //   } catch (err) {
    //     console.log(err.message)
    //     return
    //   }
    //
    //   const events = response.result.items
    //   console.log(events)
    //   if (!events || events.length === 0) {
    //     // document.getElementById('content').innerText = 'No events found.'
    //     console.log('No events found.')
    //     return
    //   }
    //   // Flatten to string to display
    //   const output = events.reduce(
    //     (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
    //     'Events:\n'
    //   )
    //   this.items = events.reduce(
    //     (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
    //     'Events:\n'
    //   )
    // },
    // oauthSignIn() {
    //   // Google's OAuth 2.0 endpoint for requesting an access token
    //   const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
    //
    //   // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    //   const form = document.createElement('form')
    //   form.setAttribute('method', 'GET') // Send as a GET request.
    //   form.setAttribute('action', oauth2Endpoint)
    //
    //   // Parameters to pass to OAuth 2.0 endpoint.
    //   const params = {
    //     client_id: this.CLIENT_ID,
    //     redirect_uri: 'http://localhost:8085/',
    //     response_type: 'token',
    //     scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
    //     include_granted_scopes: 'true',
    //     state: 'pass-through value',
    //   }
    //
    //   // Add form parameters as hidden input values.
    //   for (let p in params) {
    //     const input = document.createElement('input')
    //     input.setAttribute('type', 'hidden')
    //     input.setAttribute('name', p)
    //     input.setAttribute('value', params[p])
    //     form.appendChild(input)
    //   }
    //
    //   // Add form to page and submit it to open the OAuth 2.0 endpoint.
    //   document.body.appendChild(form)
    //   form.submit()
    // },
    // getData(_, requestOptions) {
    //   const PUBLIC_KEY = this.CLIENT_ID
    //   const CALENDAR_ID = this.API_KEY
    //   const dataUrl = [
    //     'https://www.googleapis.com/calendar/v3/calendars/',
    //     CALENDAR_ID,
    //     '/events?key=',
    //     PUBLIC_KEY,
    //   ].join('')
    //
    //   axios.get(dataUrl, requestOptions).then(data => {
    //     console.log(data)
    //   })
    // },
    // handleClientLoad() {
    //   this.api.load('client:auth2', this.initClient)
    // },
    // initClient() {
    //   let vm = this
    //   vm.api.client
    //     .init({
    //       apiKey: this.API_KEY,
    //       clientId: this.CLIENT_ID,
    //       discoveryDocs: this.DISCOVERY_DOCS,
    //       scope: this.SCOPES,
    //     })
    //     .then(_ => {
    //       vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.authorized)
    //     })
    // },
    //
    // handleAuthClick(event) {
    //   console.log()
    //   Promise.resolve(this.api.auth2.getAuthInstance().signIn()).then(_ => {
    //     this.authorized = true
    //   })
    // },
    // handleSignOutClick(event) {
    //   Promise.resolve(this.api.auth2.getAuthInstance().signOut()).then(_ => {
    //     this.authorized = false
    //   })
    // },
    // getEvents() {
    //   console.log('---- ----')
    //   let vm = this
    //   vm.api.client.calendar.events
    //     .list({
    //       calendarId: 'primary',
    //       timeMin: moment(this.filters.start).format('YYYY-MM-DDTHH:mm:ss.SZ'),
    //       timeMax: moment(this.filters.end).format('YYYY-MM-DDTHH:mm:ss.SZ'),
    //       showDeleted: false,
    //       singleEvents: true,
    //       maxResults: 10,
    //       orderBy: 'startTime',
    //     })
    //     .then(response => {
    //       console.log(response)
    //       this.items = response.result.items
    //     })
    // },
    onEvents(e) {
      console.log(e)
      if (e === 'toggle-weekends') this.calendarOptions.weekends = !this.calendarOptions.weekends
    },
    async updateDataCalendar() {
      await TasksService.getTasks();
      const tasks = this.$store.state.osBoard.tasks.map(item => ({
        title: item.subject,
        start: item.created_at,
        end: item.due_date,
        allDay: true,
        taskId: item.id,
        extendedProps: {
          calendar: 'Task',
        },
      }));
      const events = await DashboardService.getEvents().then(e => {
        return e.data.map((el, index) => ({
          id: index,
          allDay: false,
          end: el.due_date,
          extendedProps: {
            ...el,
            calendar: 'Event',
          },
          className: 'fc-event-board',
          start: el.start_date,
          title: el.title,
        }));
      });
      this.calendarOptions.events = [...tasks, ...events];
      // await this.updateCalendar()
    },
    selectArea(info) {
      this.isEventHandlerSidebarActive = true;
      eventBus.$emit('datetime-for-new-event', info.start);
    },
    async updateCalendar() {
      let calendarEl = await this.$refs.refCalendar;

      let calendar = await new Calendar(calendarEl, this.calendarOptions);

      calendar.render();
    },
    openModal() {
      this.isEventHandlerSidebarActive = false;
    },
    eventChange(e) {
      this.changeDates(e.event.startStr, e.event.endStr, e.event.extendedProps.taskId);
    },
    async changeDates(start, end, id) {
      await TasksService.updateTask(id, {
        start_date: moment(start, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm'),
        due_date: moment(end, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm'),
      }).then(() => {
        this.updateDataCalendar();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Start and due dates changed',
            icon: 'BellIcon',
          },
        });
      });
    },
    eventColor(event) {
      if (event.event.extendedProps.calendar === 'Meet') {
        return [`bg-light-success`];
      } else if (event.event.extendedProps.calendar === 'Task') {
        return [`bg-light-primary`];
      } else {
        return [`bg-light-info`];
      }
    },
    showSidebar(info) {
      if (info.jsEvent.shiftKey) {
        window.open(info.event.extendedProps.link, '_blank');
      } else {
        this.isEventHandlerSidebarActive = true;
        this.$store.commit('calendar/CHANGE_EDIT_STATUS', true);
        this.$store.commit('calendar/GET_MEET_ID', info.event.id);
        eventBus.$emit('set-meet-data', info.event);
      }
    },
    clearEventData() {
      event.value = JSON.parse(JSON.stringify(this.blankEvent));
    },
    async addEvent(e) {
      console_logger(
        {
          ...e.extendedProps,
          location: e.extendedProps.location?.value,
          project: this.$store.state.osBoard.selectedProject.id,
        },
        'info'
      );

      await DashboardService.createEvent({
        ...e.extendedProps,
        due_date: moment(e.extendedProps.start_date, 'YYYY-MM-DD HH:mm')
          .add(e.extendedProps.duration, 'minutes')
          .format('YYYY-MM-DD HH:mm'),
        location: e.extendedProps.location?.value,
        project: this.$store.state.osBoard.selectedProject.id,
      }).then(e => {
        this.isEventHandlerSidebarActive = false;

        const members = this.$refs.calendarMeeting.selectedMembers
          .filter(el => el.id !== this.$store.state.osBoard.user.id)
          .map(el => el.id);

        if (members.length > 0) DashboardService.addMembers(members, e.data.id);
      });

      await this.updateDataCalendar();
    },
    closeEvent() {
      setTimeout(() => {
        this.$store.commit('calendar/CHANGE_EDIT_STATUS', false);
      }, 400);
    },
    removeEvent(id) {
      if (window.confirm('Do you really want to delete?')) {
        // db.collection(`events-${this.$store.state.osBoard.user.id}`)
        //   .doc(id)
        //   .delete()
        //   .then(() => {
        //     console.log('Document deleted!');
        //     this.updateDataCalendar();
        //   })
        //   .catch(error => {
        //     console.error(error);
        //   });
      }
    },
    updateEvent() {
      console_logger('updateEvent');
    },
    createEvent() {
      this.isEventHandlerSidebarActive = true;
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/apps/calendar.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.app-calendar {
  margin-bottom: 0 !important;
  height: 100%;
  //border-radius: 0.65rem !important;
  //box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  overflow: unset;

  .app-calendar-sidebar {
    min-width: 18rem;
    border-top-left-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
  }

  .border-calendar {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .fc-popover {
    .fc-popover-body *:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  .fc {
    .fc-scrollgrid {
      > tbody {
        .fc-scrollgrid-sync-table {
          > tbody {
            > tr:first-child {
              .tooltip-event {
                top: 25px;
              }
            }

            > tr:not(:first-child) {
              .tooltip-event {
                top: -50px;
              }
            }
          }
        }
      }
    }

    .fc-toolbar {
      .fc-button {
        padding: 0.138rem 0.5rem;
      }

      .fc-button-group {
        .fc-dayGridMonth-button,
        .fc-timeGridWeek-button,
        .fc-timeGridDay-button,
        .fc-listMonth-button {
          padding: 0.138rem 0.7rem;
        }

        .fc-dayGridMonth-button:first-child,
        .fc-timeGridWeek-button:first-child,
        .fc-timeGridDay-button:first-child,
        .fc-listMonth-button:first-child {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        .fc-dayGridMonth-button:last-child,
        .fc-timeGridWeek-button:last-child,
        .fc-timeGridDay-button:last-child,
        .fc-listMonth-button:last-child {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }

    .fc-toolbar-title {
      font-size: 20px !important;
      --tw-text-opacity: 1 !important;
      color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
    }

    .fc-header-toolbar {
      .fc-toolbar-chunk:first-child {
        > div {
          display: flex;
          align-items: center;

          > button:nth-child(2) {
            background-color: #0000 !important;
            border-color: #0095e8 !important;
            color: #0095e8;
            box-shadow: none !important;
          }
        }
      }
    }

    .fc-direction-ltr {
      .fc-button-group {
        > .fc-button:not(:last-child) {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        > .fc-button:not(:first-child) {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }

        > .fc-button {
          padding: 0.3rem 0.6rem !important;
        }
      }
    }

    .fc-daygrid-body-natural {
      .fc-daygrid-day-events {
        margin-bottom: 0.2em;
      }
    }

    .fc-h-event {
      border: 1px solid #0095e8 !important;
      background-color: #f7f9fc !important;
    }

    .fc-daygrid-block-event {
      border: none !important;
    }

    //.bg-light-info.fc-list-event:hover td {
    //    background: rgb(0 149 231 / 8%) !important;
    //}

    .bg-light-info {
      background: none !important;

      &.fc-h-event,
      &.fc-v-event {
        border-color: #0095e8;
      }
    }

    .fc-list .fc-event,
    .fc-daygrid-event-harness .fc-event {
      padding: 0;
      cursor: pointer;

      .fc-event-main {
        display: flex;
        align-items: center;
        width: 100%;

        .dot-event {
          min-width: 3px;
          height: 14px;
          border-radius: 2px;
          background-color: #0095e8;
          margin-right: 5px;
        }
      }
    }

    .fc-daygrid-event-harness-abs {
      margin-top: 0 !important;
    }

    .fc-timegrid {
      .fc-timegrid-event {
        //background-color: #0095e817 !important;
        border: 0;
        top: 1px;
        bottom: 2px;
        right: -2px;
      }
    }

    .fc-list-event {
      td {
        vertical-align: middle;
      }

      .event-task,
      .event-meet {
        .dot-event {
          display: none;
        }
      }
    }

    .fc-list-event,
    .fc-daygrid-event {
      .tooltip-event {
        position: absolute;
        background-color: #fff;
        box-shadow: 0 4px 10px 4px rgb(34 41 47 / 10%);
        padding: 5px 8px;
        border-radius: 4px;
        left: 0;
        max-width: 100%;
        white-space: break-spaces;
        transition: all 0.1s linear;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
      }

      .event-task {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: 400;
        //background: #abcee33d !important;
        //color: $primary;
        padding: 4px 10px;
        //border-radius: 3px;
        font-size: 0.8rem;
        //border: 1px solid #007bb6;

        &:hover {
          .tooltip-event {
            visibility: visible;
            opacity: 1;
          }
        }

        .dot-event {
          margin-right: 7px !important;
        }
      }

      .event-meet {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        //background: #edf5ef !important;
        color: #219b40;
        padding: 2px 0;
        border-radius: 3px;
        font-size: 0.8rem;

        .dot-event {
          background-color: #219b40 !important;
          min-height: 6px !important;
          min-width: 6px !important;
          border-radius: 50% !important;
          margin-right: 5px;
        }

        &:hover {
          .tooltip-event {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .fc-timegrid-col {
      .fc-timegrid-event {
        //background-color: #0095e817 !important;

        .event-meet {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          //background: #edf5ef !important;
          color: #219b40;
          padding: 4px 5px;
          border-radius: 3px;
          font-size: 0.8rem;

          .dot-event {
            background-color: #219b40 !important;
            height: 5px !important;
            width: 5px !important;
            border-radius: 50% !important;
            margin-right: 5px;
          }

          &:hover {
            .tooltip-event {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }

    .fc-view-harness {
      min-height: 600px;
      margin: 0 -1.5rem 0 -1.6rem;
    }

    .fc-scroller,
    .fc-view-harness,
    .fc-list {
      border-bottom-right-radius: 0.5rem;
    }

    .fc-daygrid-day-frame {
      min-height: 100px;
    }

    .fc-dayGridMonth-view {
      .fc-day-today {
        box-shadow: inset 0 3px 0 #0095e8;
        background-color: #e1e9f34f !important;
      }
    }

    .fc-scroller-harness {
      overflow: unset;
    }

    .fc-theme-standard {
      .fc-popover {
        border: 1px solid #ebe9f1;
        transform: translate(5px, 5px);
      }
    }

    .fc-list-event,
    .fc-daygrid-event {
      &.fc-event-start {
        margin-left: 0 !important;

        .event-task {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }

      &.fc-event-end {
        margin-right: 0 !important;

        .event-task {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }

    .fc-highlight {
      background: #f0f5f8 !important;
      border-radius: 0.45rem;
    }

    .fc-daygrid-day-number {
      padding: 2px 6px;
    }

    //.fc-event:hover,
    //.fc-event-selected {
    //}
    --fc-list-event-hover-bg-color: #0095e817 !important;

    .fc-timegrid-now-indicator-arrow,
    .fc-timegrid-now-indicator-line {
      border-color: #0095e8 !important;
    }

    .fc-timegrid-now-indicator-arrow {
      border-radius: 50%;
      left: 50px;
      border-width: 5px 0 5px 10px;
    }

    .fc-event-resizer-end,
    .fc-event-resizer-start {
      cursor: e-resize;
      //right: calc(-0.5 * var(--fc-event-resizer-thickness, 8px));
      background: #0095e8;
      width: 6px;
      height: 100%;
      z-index: 576;

      &:hover {
        display: block;
      }
    }

    .fc-event-resizer-end {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      right: 0 !important;
    }

    .fc-event-resizer-start {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      left: 0 !important;
    }
  }

  .fc-timeGridDay-view,
  .fc-timeGridWeek-view {
    .event-meet {
      margin-top: 5px;
    }

    .fc-timegrid-event {
      display: flex;
      flex-direction: column;
      top: 2px !important;
      bottom: 1px !important;
      right: -1px !important;
    }

    .fc-event-resizer-end,
    .fc-event-resizer-start {
      background: #0095e8;
      width: 100%;
      height: 4px;
      z-index: 576;
    }

    .fc-event-resizer-start {
      top: 0 !important;
      cursor: n-resize;
      border-radius: 6px 6px 0 0;
    }

    .fc-event-resizer-end {
      bottom: 0 !important;
      cursor: s-resize;
      border-radius: 0 0 6px 6px;
    }
  }

  .fc-event-board {
    .event-meet {
      div {
        font-size: 12px;
      }
    }

    .fc-list-event-dot {
      border-color: #219b40 !important;
    }

    .fc-event-resizer-start,
    .fc-event-resizer-end {
      background: #219b40 !important;
    }
  }

  .fc-event-board:hover {
    td {
      background: rgba(33, 155, 64, 8%) !important;
    }
  }

  .fc-timegrid-event.fc-event-board {
    background: #219b4017 !important;
  }
}

.dark-layout {
  .app-calendar {
    .fc {
      .fc-scrollgrid-section-sticky > * {
        background: #283046;
      }

      .fc-h-event {
        background-color: #f7f9fc05 !important;
      }

      .fc-day-today {
        background-color: #232939 !important;

        .fc-daygrid-day-top a {
          color: #0095e8;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app-calendar {
    .fc .fc-button {
      font-size: 12px;
    }

    .fc .fc-toolbar .fc-toolbar-title {
      font-size: 16px;
    }

    .fc .fc-toolbar.fc-header-toolbar {
      justify-content: center;
    }
  }
}
</style>