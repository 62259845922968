<template>
  <b-sidebar
    id="sidebar-add-new-event"
    :visible="isEventHandlerSidebarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="val => $emit('update:is-event-handler-sidebar-active', val)"
  >
    <template #default="{hide}">
      <div class="card-developer-meetup">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ !$store.state.calendar.editStatus ? $t('New event') : $t('Event') }}
          </h5>
          <div class="tw-flex tw-items-center">
            <feather-icon
              v-if="$store.state.calendar.editStatus"
              class="cursor-pointer"
              icon="TrashIcon"
              @click="
                () => {
                  $emit('remove-event', meet.id);
                }
              "
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <!-- Body -->
        <b-card-body>
          <div v-if="$store.state.calendar.editStatus">
            <div class="meetup-header d-flex align-items-center mb-2">
              <div class="meetup-day">
                <h6 class="mb-0">{{ dayWeek }}</h6>
                <h3 class="mb-0">{{ dayNumber }}</h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25">
                  {{ meet.title }}
                </b-card-title>
                <b-card-text class="mb-0">
                  {{ meet.agenda }}
                </b-card-text>
              </div>
            </div>

            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded size="36" variant="light-primary">
                  <feather-icon icon="CalendarIcon" size="22" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">{{ formatDateTime }}</h6>
                <small>{{ formatTime }}</small>
              </b-media-body>
            </b-media>

            <b-media :class="{'align-items-center': !meet.link}" no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded size="36" variant="light-primary">
                  <feather-icon icon="MapPinIcon" size="22" />
                </b-avatar>
              </b-media-aside>
              <b-media-body v-if="meet.location" style="width: calc(100% - 50px)">
                <h6 class="mb-0">{{ meet.location }}</h6>
                <div v-if="meet.link" class="text-truncate" style="font-size: 12px">
                  <b-link :href="meet.link" target="_blank">{{ meet.link }}</b-link>
                </div>
              </b-media-body>
            </b-media>

            <b-avatar-group class="mt-1 pt-50" size="34px">
              <b-avatar
                v-for="(member, index) in meet.members"
                :key="index"
                v-b-tooltip.hover.bottom="member.title"
                :src="member.avatar"
                class="pull-up"
              />
              <!--                            <h6 class="align-self-center cursor-pointer ml-1 mb-0">+42</h6>-->
            </b-avatar-group>
          </div>

          <div v-else>
            <validation-observer ref="formValidation">
              <!-- Title -->
              <b-form-group label="Title" label-for="task-title">
                <validation-provider #default="{errors}" name="Title" rules="required">
                  <b-form-input
                    id="task-title"
                    v-model="meet.title"
                    :state="errors.length > 0 ? false : null"
                    autofocus
                    placeholder="Write something..."
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Agenda -->
              <b-form-group label="Agenda" label-for="task-agenda">
                <validation-provider #default="{errors}" name="Agenda">
                  <b-form-textarea
                    id="task-agenda"
                    v-model="meet.agenda"
                    autofocus
                    class="tw-rounded-lg"
                    placeholder="Write something..."
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Project -->
              <b-form-group label="Project" label-for="project">
                <div class="mb-1">
                  <validation-provider #default="{errors}" name="Project">
                    <v-select
                      v-model="project"
                      :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                      :options="$store.state.osBoard.projects"
                      label="title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>

              <!-- Members -->
              <b-form-group label="Members" label-for="members">
                <validation-provider #default="{errors}" name="Members">
                  <v-select
                    id="members"
                    v-model="selectedMembers"
                    :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                    :options="$store.state.osBoard.users"
                    class="members-selector"
                    label="title"
                    multiple
                  >
                    <template #option="{title}">
                      <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                    </template>

                    <template #selected-option="{title}">
                      <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Location -->
              <b-form-group class="mb-0" label="Location" label-for="location">
                <validation-provider #default="{errors}" name="Location">
                  <v-select
                    id="location"
                    v-model="meet.location"
                    :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                    :options="locations"
                    class="assignee-selector"
                    label="value"
                  >
                    <template #option="{value}">
                      <span class="ml-50 d-inline-block align-middle">{{ value }}</span>
                    </template>

                    <template #selected-option="{value}">
                      <span class="ml-50 d-inline-block align-middle">{{ value }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-input-group v-if="showBlockLink.includes(meet.location.label)" class="mt-50" size="sm">
                <b-input-group-prepend is-text>
                  <feather-icon icon="LinkIcon" size="18" />
                </b-input-group-prepend>
                <b-form-input v-model="meet.link" placeholder="Link" />
              </b-input-group>

              <!-- Notify before -->
              <b-form-group class="mt-1" label="Notify before" label-for="notify-before">
                <b-input-group id="notify-before" append="minutes" class="input-group-merge">
                  <b-form-input v-model="meet.notify_before" placeholder="60" type="number" />
                </b-input-group>
              </b-form-group>

              <b-tabs v-model="tabIndex" class="mt-1">
                <b-tab>
                  <template #title>
                    <!--                                        <feather-icon icon="HomeIcon" />-->
                    <span>Does not repeat</span>
                  </template>

                  <!-- Start date -->
                  <div class="mb-1">
                    <div class="d-flex align-items-center">
                      <validation-provider #default="{errors}" name="Start date">
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                          class="mb-0"
                          label="Start date"
                          label-for="start-date"
                        >
                          <date-picker
                            id="start-date"
                            v-model="meet.start_date"
                            :format="$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'"
                            :minute-step="15"
                            placeholder="Select date/time"
                            type="datetime"
                            value-type="YYYY-MM-DD HH:mm"
                          ></date-picker>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider #default="{errors}" name="Duration">
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                          class="ml-1 mb-0"
                          label="Duration"
                          label-for="duration"
                        >
                          <date-picker
                            id="duration"
                            v-model="meet.duration"
                            :minute-step="15"
                            format="HH:mm"
                            placeholder="00:00"
                            type="time"
                            value-type="HH:mm"
                          ></date-picker>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <p v-if="meet.due_date.length > 0" class="font-small-2 m-0 mt-25 text-secondary">
                      Due date: {{ meet.due_date }}
                    </p>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon icon="RepeatIcon" />
                    <span>Custom recurrence</span>
                  </template>

                  <!-- Days -->
                  <b-form-group label="Days" label-for="days">
                    <div class="mb-1">
                      <validation-provider #default="{errors}" name="Days">
                        <v-select
                          id="days"
                          v-model="meet.weekday"
                          :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                          :options="optionsDays"
                          :reduce="value => value.label"
                          label="value"
                          multiple
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-form-group>

                  <div class="mb-1">
                    <div class="d-flex align-items-center flex-wrap">
                      <validation-provider #default="{errors}" name="Start date">
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                          class="mb-0"
                          label="Start date"
                          label-for="start-date"
                        >
                          <date-picker
                            id="start-date"
                            v-model="meet.start_date"
                            :format="$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'"
                            :minute-step="15"
                            placeholder="Select date/time"
                            type="datetime"
                            value-type="YYYY-MM-DD HH:mm"
                          ></date-picker>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider #default="{errors}" name="Duration">
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                          class="ml-1 mb-0"
                          label="Duration"
                          label-for="duration"
                        >
                          <date-picker
                            id="duration"
                            v-model="meet.duration"
                            :minute-step="15"
                            format="HH:mm"
                            placeholder="00:00"
                            type="time"
                            value-type="HH:mm"
                          ></date-picker>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider #default="{errors}" name="Due date">
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                          class="mb-0 mt-1 d-block"
                          label="Due date"
                          label-for="due-date"
                        >
                          <date-picker
                            id="due-date"
                            v-model="meet.due_date"
                            :format="$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'"
                            :minute-step="15"
                            placeholder="Select date"
                            type="date"
                            value-type="YYYY-MM-DD"
                          ></date-picker>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </validation-observer>
          </div>

          <div v-if="!$store.state.calendar.editStatus" class="mt-2 mb-75 d-flex">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="rounded-nrm"
              variant="primary"
              @click="createMeetup"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="rounded-nrm ml-1"
              variant="outline-primary"
              @click="$emit('update:is-event-handler-sidebar-active', false)"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-card-body>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BButton,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BSidebar,
  BTab,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {email, required, url} from '@validations';
import moment from 'moment';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/index.css';
import {eventBus} from '@/main';
import store from '@/store';
import ProjectsService from '@/services/projects.service';

export default {
  components: {
    BButton,
    BSidebar,
    BCardBody,
    BMedia,
    BMediaBody,
    BMediaAside,
    BFormGroup,
    BAvatar,
    BFormTextarea,
    BCardTitle,
    BCardText,
    BAvatarGroup,
    BLink,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      meet: {
        title: '',
        duration: '00:00',
        start_date: '',
        due_date: '',
        location: '',
        link: '',
        description: '',
        notify_before: 0,
        weekday: [],
      },
      locations: [
        {label: 'google_meet', value: 'Google Meet'},
        {label: 'discord', value: 'Discord'},
        {label: 'zoom', value: 'Zoom'},
        {label: 'other', value: 'Other'},
      ],
      optionsDays: [
        {label: 1, value: 'ПН'},
        {label: 2, value: 'ВТ'},
        {label: 3, value: 'СР'},
        {label: 4, value: 'ЧТ'},
        {label: 5, value: 'ПТ'},
        {label: 6, value: 'СБ'},
        {label: 7, value: 'ВС'},
      ],
      showBlockLink: ['google_meet', 'discord', 'zoom'],
      mediaData: [
        {
          avatar: 'CalendarIcon',
          title: 'Sat, May 25, 2020',
          subtitle: '10:AM to 6:PM',
        },
        {
          avatar: 'MapPinIcon',
          title: 'Central Park',
          subtitle: 'Manhattan, New york City',
        },
      ],
      members: [],
      selectedMembers: [],
      avatars: [
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          fullName: 'Billy Hopkins',
        },
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
          fullName: 'Amy Carson',
        },
      ],
      tabIndex: 0,
    };
  },
  mounted() {
    ProjectsService.getProjects();
    this.meet.start_date = moment().hour(0).minutes(0).toDate();
    eventBus.$on('datetime-for-new-event', data => {
      this.meet.start_date = moment(data).format('YYYY-MM-DD HH:mm');
    });
    eventBus.$on('set-meet-data', data => {
      this.meet = data.extendedProps;
    });
  },
  computed: {
    project: {
      get: () => store.state.osBoard.selectedProject,
      set: val => {
        localStorage.setItem('selected_project', JSON.stringify(val));
        store.commit('osBoard/SET_PROJECT', val);
      },
    },
    currentDate() {
      return this.meet.due_date.length === 0
        ? moment().format(this.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm')
        : this.meet.due_date;
    },
    formatDateTime() {
      moment.locale(this.$i18n.locale);
      const dayWeek = moment(this.currentDate).format('ddd');
      const dayWeekBigFirst = dayWeek[0].toUpperCase() + dayWeek.slice(1);
      const day = moment(this.currentDate).format('DD');
      const month = moment(this.currentDate).format('MMM');
      const year = moment(this.currentDate).format('YYYY');
      if (this.$i18n.locale === 'en') {
        return `${dayWeekBigFirst}, ${month} ${day}, ${year}`;
      } else {
        return `${dayWeekBigFirst}, ${day} ${month}, ${year}`;
      }
    },
    dayWeek() {
      return moment(this.currentDate).format('ddd').toUpperCase();
    },
    dayNumber() {
      return moment(this.currentDate).format('DD');
    },
    formatTime() {
      return (
        moment(this.meet.start_date, 'YYYY-MM-DD HH:mm').format('HH:mm') +
        ' - ' +
        moment(this.meet.due_date, 'YYYY-MM-DD HH:mm').format('HH:mm')
      );
    },
  },
  watch: {
    'meet.duration'() {
      this.dueDate();
    },
    'meet.start_date'() {
      this.dueDate();
    },
    isEventHandlerSidebarActive() {
      if (!this.isEventHandlerSidebarActive) {
        this.$emit('close');
        setTimeout(() => {
          this.meet = {
            title: '',
            duration: '00:00',
            start_date: moment().hour(0).minutes(0).toDate(),
            due_date: '',
            location: '',
            link: '',
            agenda: '',
            notify_before: 0,
          };
        }, 200);
      }
    },
  },
  methods: {
    onContext(ctx) {
      this.meet.due_date = ctx.selectedYMD;
    },
    createMeetup() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          let data = {
            title: this.meet.title,
            start: this.meet.start_date,
            end: moment(
              this.meet.due_date,
              this.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'
            ).format('YYYY-MM-DD HH:mm'),
            allDay: false,
            extendedProps: {
              calendar: 'Meet',
              ...this.meet,
              duration:
                moment(this.meet.duration, 'HH:mm').hours() * 60 + moment(this.meet.duration, 'HH:mm').minutes(),
            },
          };

          if (this.tabIndex === 1) {
            data.extendedProps.repeatable = true;
          }

          this.$emit('add-event', data);
        }
      });
    },
    dueDate() {
      const hours = Number(this.meet.duration.split(':')[0]);
      const minutes = Number(this.meet.duration.split(':')[1]);
      console.log(this.meet.due_date);
      this.meet.due_date = moment(this.meet.start_date)
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .format(this.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm');
    },
  },
  beforeDestroy() {
    eventBus.$off('datetime-for-new-event');
    eventBus.$off('set-meet-data');
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.b-form-timepicker {
  .dropdown-menu {
    min-width: 8rem;

    .b-time {
      min-width: 105px;
    }
  }
}

.members-selector {
  .vs__dropdown-menu {
    max-height: 160px;
  }
}

.mx-datepicker {
  width: 170px;
}

#duration {
  width: 90px;
}

.input-group-merge .input-group-append .input-group-text {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>