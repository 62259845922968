import axios from 'axios'
import axiosIns, {auth} from '@/libs/axios'

class DashboardService {
  getWeather(lat, lng, lang, units) {
    return axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${'31978c6fee3db4d4cd6a2d93fda48e7f'}&lang=${lang}&units=${units}`
    )
  }

  createEvent(body) {
    return axiosIns.post('meeting/create/', body, {headers: auth()})
  }

  addMembers(body, id) {
    return axiosIns.post(`meeting/members/${id}/`, body, {headers: auth()})
  }

  getEvents() {
    return axiosIns.get('meeting/', {headers: auth()})
  }
}

export default new DashboardService()
