<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        aria-controls="sidebar-add-new-event"
        block
        class="tw-rounded-lg"
        variant="primary"
        @click="$emit('is-event-handler-sidebar-active')"
      >
        {{ $t('Create event') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        :variant="!$store.state.calendar.fullscreen ? 'flat-primary' : 'flat-danger'"
        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-mt-2 tw-rounded-lg"
        @click="() => $store.commit('calendar/CHANGE_FULLSCREEN', !$store.state.calendar.fullscreen)"
      >
        <feather-icon :icon="!$store.state.calendar.fullscreen ? 'MaximizeIcon' : 'MinimizeIcon'" class="mr-50" />
        {{ $t('Fullscreen') }}
      </b-button>
      <div class="mt-1">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Params') }}</span>
        </h5>
<!--        <b-form-checkbox class="mb-1">-->
<!--          {{ $t('Show all') }}-->
<!--        </b-form-checkbox>-->
        <b-form-group>
          <b-form-checkbox-group name="event-filter" stacked>
            <b-form-checkbox
              v-for="item in params"
              :key="item.label"
              :class="`tw-text-${item.color}-500`"
              :value="item.label"
              class="mb-50"
              name="event-filter"
              @change="$emit('on-events', item.eventName)"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/online-calendar-animate.svg')" class="p-1" />
  </div>
</template>

<script>
import {BButton, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BImg} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    params: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
};
</script>

<style></style>