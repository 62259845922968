<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <Dashboard />
    <Calendar />
  </div>
</template>

<script>
import Calendar from '@/components/Calendar/Calendar';
import Dashboard from '@/components/Home/Dashboard';

export default {
  components: {
    Dashboard,
    Calendar,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 991px) {
  .row {
    flex-direction: column-reverse;
  }
}
</style>
