<template>
  <div>
    <h3 class="tw-mb-0 tw-font-semibold tw-text-gray-700">
      {{ greeting }}{{ fullName ? ', ' + fullName : '' }}{{ dateJoined }}
    </h3>
    <p class="tw-mb-2 tw-text-lg tw-text-gray-600 tw-flex tw-items-center">
      {{
        `${$t('Today')} ${clock}${
          weather !== null
            ? ', ' + $t('on the street') + ' ' + temperature + '  C,  ' + weather.weather[0].description
            : ''
        }`
      }}
      <b-spinner v-if="showWeather" class="ml-50" label="Small Spinner" small />
    </p>
  </div>
</template>

<script>
import moment from 'moment';
import DashboardService from '@/services/dashboard.service';
import i18n from '@/libs/i18n';
import store from '@/store';
import {BSpinner, VBTooltip} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import dayjs from 'dayjs';

export default {
  components: {
    BSpinner,
  },
  data() {
    return {
      clock: '',
      timer: null,
      showWeather: true,
    };
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted() {
    this.weatherData();
    this.clockUpdate();
    localStorage.setItem(
      'userData',
      JSON.stringify({
        ability: [
          {action: 'manage', subject: 'Projects'},
          {action: 'manage', subject: 'Tasks'},
        ],
      })
    );
  },
  computed: {
    fullName() {
      const {first_name, last_name} = this.$store.state.osBoard.user;
      return first_name ? `${first_name} ${last_name}` : null;
    },
    dateJoined() {
      const {date_joined} = this.$store.state.osBoard.user;
      const dateStart = dayjs(date_joined, 'YYYY-MM-DD HH:mm:ss');
      const daysTotal = dayjs().diff(dateStart, 'd');
      return date_joined ? `, you've been with us ${daysTotal} ${daysTotal === 1 ? 'day' : 'days'}` : '';
    },
    weather() {
      return this.$store.state.dashboard.weather;
    },
    temperature() {
      return Math.round(Number(this.$store.state.dashboard.weather.main.temp));
    },
    lang() {
      return this.$i18n.locale;
    },
    greeting() {
      const today = moment().hour();
      if (today >= 0 && today < 5) return this.$t('Good night');
      if (today >= 5 && today < 12) return this.$t('Good morning');
      if (today >= 12 && today < 18) return this.$t('Good day');
      if (today >= 18 && today <= 23) return this.$t('Good evening');
      return null;
    },
  },
  watch: {
    lang() {
      this.weatherData();
      this.clockUpdate();
    },
  },
  methods: {
    clockUpdate() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
      moment.locale(this.$i18n.locale);
      const dayWeek = moment().format('dddd');
      const dayWeekBigFirst = dayWeek[0].toLowerCase() + dayWeek.slice(1);
      this.clock = dayWeekBigFirst + ', ' + moment().format('DD MMMM, YYYY - HH:mm');
      this.timer = setInterval(() => {
        this.clock = dayWeekBigFirst + ', ' + moment().format('DD MMMM, YYYY - HH:mm');
      }, 1000);
    },
    weatherData() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          // Текущие координаты.
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          DashboardService.getWeather(lat, lng, i18n.locale, 'metric').then(r => {
            store.commit('dashboard/GET_WEATHER', r.data);
            this.showWeather = false;
          });
        });
      } else {
        console.log('Геолокация не доступна');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-border-sm {
  border-width: 0.13em;
}
</style>